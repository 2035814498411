import { Button, Col, Row, Form } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import Slider from '../components/slider';
import icon from 'images/icon.svg';
import Layout from 'components/layout';
import axios from 'axios';

const Demo = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [time, setTime] = useState('');
    const [jobTitle, setJobTitle] = useState('');

    const resetFormData = () => {
        setCompanyName('');
        setEmail('');
        setTime('');
        setName('');
        setJobTitle('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: name,
            email: email,
            time: time,
            company: companyName,
            position: jobTitle,
        };
        submitData(data);
        resetFormData();
    };

    const submitData = async (data) => {
        const options = {
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: "https://script.google.com/macros/s/AKfycbwu-r4XdO1qoQ55E3bQ-Z0Mq9PQpgrUhrjOGLjsF3NUnGPC2dLUwUHDx0LycsYivdZnKg/exec?sheetName=demo",
            data: JSON.stringify(data)
        }
        //eslint-disable-next-line
        const response = await axios(options); lastName
    }

    return (
        <Layout>
            <div>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>Let Us Take You Through Our Platform</title>
                    <meta name="description" content="Among the people who have seen the demo, 18% have requested for another demo and 7% have decided to adopt QuotaCal in their organisation" />
                </Helmet>
                <div className="home">
                    <div>
                        <p className="title">Let Us Take You Through Our Platform</p>
                        <p className="description">FACT: Among the people who have seen the demo, 18% have requested for another demo and 7% have decided to adopt QuotaCal in their organisation</p>
                    </div>
                </div >
                <Row style={{ marginBottom: '50px' }}>
                    <Col md={{ span: 5, offset: 1 }} xs={{ span: 10, offset: 1 }} style={{ background: 'rgba(196, 101, 255, 0.3)', borderRadius: '20px', fontFamily: 'Inria Sans' }}>
                        <p style={{ textAlign: 'center', color: '#5958C7', fontSize: '48px', fontFamily: 'Inria Sans' }}>Schedule a Demo</p>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', fontSize: '24px', color: "#8C8C8C" }}>
                            <Form style={{ width: '90%', marginBottom: '30px' }} onSubmit={handleSubmit} >
                                <Form.Control className="formControl" value={name} onChange={(e) => setName(e.target.value)} required placeholder="First Name" />
                                <Form.Control className="formControl" value={time} onChange={(e) => setTime(e.target.value)} required placeholder="Preferred time for demo" />
                                <Form.Control className="formControl" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email" />
                                <Form.Control className="formControl" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required placeholder="Company Name" />
                                <Form.Control className="formControl" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required placeholder="Job Title" />
                                <Button className="button" type="submit">
                                    Submit</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 1 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <Row style={{ marginBottom: '50px' }}>
                                <p className="head">What to expect during the demo?</p>
                                <p className="desc">We will understand your requirements and show to you different modules in our application that matches your exact use-case. We will also show to you a fully functional QuotaCal account and the results achieved.</p>
                            </Row>
                            <Row>
                                <p className="head">When do I get a Free Trial?</p>
                                <p className="desc">We usually offer you a limited free trial account right after the demo so that you can play around and show it to other stakeholders in your organisation. We will also load dummy data in the free trial account so that it will look real and practical.</p>
                            </Row>
                        </div>
                    </Col>
                </Row >
                <div>
                    <p className='heading'>Hear from our clients</p>
                </div>
                <Slider />
            </div >
        </Layout>
    )
};

export default Demo;
